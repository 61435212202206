import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  ListItem,
  Spinner,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import useIsLoggedIn from "core/features/auth/hooks/useIsLoggedIn";
import useEventoDetalhado from "core/features/eventos/hooks/useEventoDetalhado";
import useGetSetorOpcoes from "core/features/eventos/hooks/useGetSetorOpcoes";
import { EventoSetorOpcao } from "core/features/eventos/types";
import colors from "core/resources/theme/colors";
import moment from "moment";
import { useFieldArray, useForm } from "react-hook-form";
import { BiChevronLeft } from "react-icons/bi";
import { MdShoppingCart } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import OutlineButton from "ui/components/Button/OutlineButton";
import Container from "ui/components/Layout/Container";
import BarStep from "../components/BarStep";
import CardIngresso from "../components/DataVisita/components/CardIngresso";
import useBackToBeginning from "../hooks/useBackToBeginning";
import useCurrentStep from "../hooks/useCurrentStep";
import { useIngressosStore } from "../hooks/useIngressosStore";
import useStepTitle from "../hooks/useStepTitle";
import IngressoContainer from "./IngressoContainer";

moment.locale("pt-br");

function ComprarIngressos() {
  const { eventoId, setorId } = useParams<{
    eventoId: string;
    setorId: string;
  }>();
  useBackToBeginning();

  const activeStep = useCurrentStep();
  const [titleStep, titleNextStep] = useStepTitle(activeStep, activeStep + 1);

  const dataSelecionada = useIngressosStore((state) => state.dataSelecionada);
  const navigate = useNavigate();

  const { data: eventoAtual } = useEventoDetalhado();
  const {
    watch,
    setValue,
    getValues,
    control,
    setError,
    clearErrors,
    formState,
    trigger,
  } = useForm<{
    items: { id: number; quantity: number; item: EventoSetorOpcao }[];
    termo: boolean;
  }>({
    defaultValues: {
      items: [],
    },
  });

  const valorTotal = Object.entries(watch("items")).reduce((acc, curr) => {
    const [, value] = curr;
    return acc + value.quantity * value.item.valorBase;
  }, 0);

  const { fields, append } = useFieldArray({
    control,
    name: "items",
  });

  const { isLoading } = useGetSetorOpcoes((items) => {
    items?.forEach((item) => {
      append({ id: item.id, quantity: 0, item });
    });
  });

  const updateItemQuantity = (itemIndex: number, newQuantity: number) => {
    setValue(
      `items.${itemIndex}.quantity` as `items.${number}.quantity`,
      newQuantity
    );
  };

  const isLogged = useIsLoggedIn();

  return (
    <Container>
      <Flex flexDir="column">
        <Flex
          pt={["130px", "130px", "130px", "330px"]}
          mb="80px"
          gap="44px"
          flexDir={"column"}
        >
          <BarStep
            step={activeStep}
            titleNextStep={titleNextStep!}
            nextStep={activeStep + 1}
            titleStep={titleStep!}
          />

          <Stack spacing={0.25}>
            <Text
              fontWeight="medium"
              fontSize="24px"
              color={colors.purple500}
              flexWrap="wrap"
            >
              {eventoAtual?.nome}
            </Text>
            <Text fontSize="16px">{eventoAtual?.texto}</Text>
          </Stack>

          <FormControl isInvalid={formState.errors.items?.type === "required"}>
            <FormErrorMessage mb={2}>
              Você deve selecionar pelo menos um ingresso
            </FormErrorMessage>
            <Stack spacing={0}>
              <IngressoContainer
                title={`Ingressos para visita em ${moment(
                  dataSelecionada
                ).format("DD [de] MMMM [de] YYYY")}`}
              >
                {isLoading ? (
                  <Center h="160px">
                    <Spinner size="xl" color={colors.blue} />
                  </Center>
                ) : null}

                {fields?.map((field, i) => (
                  <CardIngresso
                    quantity={getValues(`items.${i}.quantity`)}
                    addQuantity={() => {
                      const currentTotal = getValues().items[i].quantity || 0;
                      updateItemQuantity(i, currentTotal + 1);
                    }}
                    removeQuantity={() => {
                      const currentTotal = getValues().items[i].quantity || 0;
                      if (currentTotal > 0) {
                        updateItemQuantity(i, currentTotal - 1);
                      }
                    }}
                    key={field.id}
                    titleCard={field.item.nome}
                    valorIngresso={
                      field.item.valorBase +
                      (field.item.valorTaxaWeb > 0
                        ? field.item.valorTaxaWeb
                        : 0)
                    }
                    valorTotalIngresso={
                      field.item.valorBase + field.item.valorTaxaWeb
                    }
                    id={field.id}
                  />
                ))}
              </IngressoContainer>
              <Flex
                w="100%"
                justify="space-between"
                h="80px"
                align="center"
                roundedBottom="16px"
                px="20px"
                boxShadow="base"
              >
                <Flex gap={4}>
                  <MdShoppingCart size={30} color={colors.blue} />
                  <Text fontSize="24px" fontWeight="bold" color={colors.blue}>
                    {fmtReal(valorTotal)}
                  </Text>
                </Flex>
                <OutlineButton
                  title="PRÓXIMO"
                  color={colors.white}
                  bg={colors.red}
                  bgBorder="#ED3237"
                  onClick={async () => {
                    const isValid = await trigger("termo");
                    if (!isValid) {
                      return;
                    }

                    if (valorTotal === 0) {
                      setError("items", { type: "required" });
                      return;
                    }

                    clearErrors("items");
                    useIngressosStore
                      .getState()
                      .setIngressos(getValues().items);

                    if (isLogged) {
                      navigate(
                        `/ingresso/${eventoId}/setor/${setorId}/pagamento`
                      );
                    } else {
                      navigate(
                        `/ingresso/${eventoId}/setor/${setorId}/identificacao`
                      );
                    }
                  }}
                />
              </Flex>

              <Box pt={4} px={4}>
                <Text fontSize="16px" color={colors.gray500}>
                  Informação das idades quanto aos valores dos ingressos.
                </Text>
                <UnorderedList fontSize="16px" color={colors.gray500} px={2}>
                  <ListItem>
                    0 a 4 anos não paga ingresso (obrigatória apresentação do
                    documento na portaria para liberação)
                  </ListItem>
                  <ListItem>
                    5 a 10 anos paga pelo ingresso “INFANTIL 5 A 10 ANOS”
                    (obrigatória apresentação de documento na portaria para
                    validação do ingresso)
                  </ListItem>
                  <ListItem>
                    A partir de 11 anos paga pelo ingresso “ADULTO”
                  </ListItem>
                  <ListItem>
                    Certifique-se da aprovação da compra do seu ingresso!
                  </ListItem>
                </UnorderedList>
              </Box>
            </Stack>
          </FormControl>
        </Flex>
      </Flex>

      <Flex justify="space-between" align="center">
        <OutlineButton
          title="VOLTAR"
          color={colors.blue}
          prevIcon={<BiChevronLeft size={20} />}
          onClick={() => navigate(-1)}
        />
      </Flex>
    </Container>
  );
}

const fmtReal = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
}).format;

export default ComprarIngressos;
