import { Box, Flex } from "@chakra-ui/react";
import moment from "moment";
import { getLabelPagamento, getLabelStatus } from "../util";
import { useNavigate } from "react-router-dom";

export default function Card({ data }: any) {
  const navigate = useNavigate();
  return (
    <Flex
      onClick={() => navigate(`/minhas-compras/${data.id}`)}
      cursor="pointer"
      w="100%"
      flexDir={"column"}
      p={"0.5rem"}
      rounded={10}
      gap={2}
      border="1px #EDF2F7 solid"
      borderBottom="3px #EDF2F7 solid"
    >
      <Flex flexDir={["column", "row", "row"]} gap={2}>
        <Box w={["100%", "50%", "50%"]}>
          <Flex flex={0.8} fontSize="16px" color="gray.500">
            #
          </Flex>{" "}
          <Flex flex={0.8} fontSize="16px">
            {data.id}
          </Flex>
        </Box>
        <Box>
          <Flex flex={0.8} fontSize="16px" color="gray.500">
            EVENTO
          </Flex>{" "}
          <Flex flex={0.8} fontSize="16px">
            {data.eventoNome}
          </Flex>
        </Box>
      </Flex>{" "}
      <Flex flexDir={["column", "row", "row"]} gap={2}>
        <Box w={["100%", "50%", "50%"]}>
          <Flex flex={0.8} fontSize="16px" color="gray.500">
            DATA E HORA DA COMPRA
          </Flex>{" "}
          <Flex flex={0.8} fontSize="16px">
            {moment(data.dataHora).format("DD/MM/YYYY HH:mm")}
          </Flex>
        </Box>
        <Box>
          <Flex flex={0.8} fontSize="16px" color="gray.500">
            STATUS
          </Flex>{" "}
          <Flex flex={0.8} fontSize="16px">
            {getLabelStatus(data.statusNome)}
          </Flex>
        </Box>
      </Flex>{" "}
      <Flex flexDir={["column", "column", "row"]} gap={2}>
        <Box w={["100%", "50%", "50%"]}>
          <Flex flex={0.8} fontSize="16px" color="gray.500">
            FORMA DE PAGAMENTO
          </Flex>{" "}
          <Flex flex={0.8} fontSize="16px">
            {getLabelPagamento(data.modalidadeNome)}
          </Flex>
        </Box>
        <Box>
          <Flex flex={0.8} fontSize="16px" color="gray.500">
            TOTAL
          </Flex>{" "}
          <Flex flex={0.8} fontSize="16px">
            {data.valorTotal.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}
