import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Select,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import useHasEnderecoCadastrado from "core/features/auth/hooks/useHasEnderecoCadastrado";
import useComprarIngressoCC from "core/features/eventos/hooks/useComprarIngressoCC";
import { useGetCartoes } from "core/features/eventos/hooks/useGetCartoes";
import colors from "core/resources/theme/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DefaultButton from "ui/components/Button/DefaultButton";
import OutlineButton from "ui/components/Button/OutlineButton";
import Field from "ui/components/Input/Field";
import TermoPoliticaModal from "ui/pages/Admin/Ingresso/TermoPoliticaModal";
import ModalEndereco from "../../../ModalEndereco";
import CadastrarCartao from "../CadastrarCartao";
import CartaoCheckbox from "./CartaoCheckbox";
import useGetParcelamentos from "./useGetParcelamentos";
import useGetUsuarioLogadoEnderecos from "core/features/auth/hooks/useGetUsuarioLogadoEnderecos";
import useGetUserInfo from "core/features/auth/hooks/useGetUserInfo";
import { validatePhone } from "core/helpers/number";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function PagarComCartao() {
  const [cadastrarCard, setCadastrarCard] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenTermo,
    onClose: onCloseTermo,
    onOpen: onOpenTermo,
  } = useDisclosure();
  const { data: enderecos, isLoading: isLoadinEnderecos } =
    useGetUsuarioLogadoEnderecos();
  const { setValue, watch, getValues, control, trigger } = useForm<{
    cartaoId?: number;
    qtdeParcelas: number;
  }>({
    defaultValues: {
      qtdeParcelas: 1,
    },
  });
  const { data: user } = useGetUserInfo();

  const [enderecoFatura, setEnderecoFatura] = useState(enderecos ? {
    cidade: enderecos?.nomeCidade,
    uf: enderecos?.nomeEstado,
    ...enderecos,
  } : null);

  const { data: cartoes, isLoading } = useGetCartoes((cartoes) => {
    const principal = cartoes.find((cartao) => cartao.principal);
    setValue("cartaoId", principal?.id);
  });

  const { hasAddress } = useHasEnderecoCadastrado();
  const { mutate, isLoading: isComprando } = useComprarIngressoCC();
  const navigate = useNavigate();

  const onSubmit = async () => {
    if (!user) return;

    if (!validatePhone(user?.phone)) {
      const instance = Swal.fire({
        title: "Celular não informado",
        confirmButtonText: 'Ir para meus dados',
        icon: "warning",
        text: "É necessario ter o celular informado nas informações do usuário para adquirir ingressos.",
      });

      instance.then(({ isConfirmed }) => {
        if (isConfirmed) {
          setTimeout(() => {
            navigate("/perfil/alterarTelefone");
          }, 250);
        }
      });
      return;
    }
    const valued = await trigger();
    if (valued) {
      if (!hasAddress && !enderecoFatura) {
        onOpen();
        return;
      }

      mutate({
        pessoaCarteiraId: getValues("cartaoId") as unknown as string,
        qtdeParcelas: getValues("qtdeParcelas"),
        dadosEndereco: enderecoFatura,
      });
    }
  };
  const parcelas = useGetParcelamentos();

  if (isLoading) return <div>Buscando cartoes...</div>;

  if (cartoes?.length === 0 || cadastrarCard)
    return (
      <CadastrarCartao
        cadastrarCard={cadastrarCard}
        setCadastrarCard={setCadastrarCard}
      />
    );
  return (
    <Stack spacing={2} w="full">
      <Box w="100%" alignSelf={"end"} mb={4}>
        <Text fontWeight="medium" fontSize="24px">
          Endereço de Fatura
        </Text>
        {enderecoFatura ? (
          <>
            <Text>
              {enderecoFatura?.logradouro}, {enderecoFatura?.numero}
            </Text>
            <Text>
              {enderecoFatura?.cidade} - {enderecoFatura?.uf}
            </Text>
            <Text>{enderecoFatura?.cepInfo}</Text>
            <Button size={"xs"} bg={colors.red} color="white" onClick={onOpen}>
              Editar Endereço
            </Button>
          </>
        ) : (
          <Text color={colors.red}>Nâo possui endereço cadastrado ainda.</Text>
        )}
      </Box>
      <Stack mb={2}>
        <Flex align="center" gap={4}>
          <Text fontWeight="medium" fontSize="20px">
            Seus cartões
          </Text>

          <OutlineButton
            title="ADICIONAR CARTÃO"
            h="32px"
            onClick={() => {
              setCadastrarCard(true);
            }}
          />
        </Flex>
        <Divider />
      </Stack>
      {cartoes?.map((cartao) => (
        <CartaoCheckbox
          canDelete={false}
          cartao={cartao}
          key={cartao?.id}
          selected={watch("cartaoId") === cartao?.id}
          onSelect={(cartao) => setValue("cartaoId", cartao?.id)}
        />
      ))}

      <Stack pt={8} gap={4}>
        <Field
          control={control}
          name="termo"
          rules={{
            required: "Você deve aceitar os termos e condições para comprar",
          }}
          render={({ field }) => (
            <Checkbox
              colorScheme="blue"
              mr={2}
              size="lg"
              spacing="1rem"
              {...field}
            >
              Li e concordo com as{" "}
              <Button variant="link" onClick={onOpenTermo}>
                condições para compra
              </Button>
            </Checkbox>
          )}
        />
        <Flex
          align="center"
          justify="space-between"
          flexDir={["column", "column", "row"]}
          gap={[4, 4, 0]}
        >
          <Field
            control={control}
            name="qtdeParcelas"
            textBorder="Parcelas"
            render={({ field }) => (
              <Select
                {...field}
                w="full"
                maxW={[null, null, "250px"]}
                color={colors.gray500}
              >
                <option value={1}>À vista - 1x - {parcelas["1x"]}</option>
                <option value={2}>2x - {parcelas["2x"]}</option>
                <option value={3}>3x - {parcelas["3x"]}</option>
              </Select>
            )}
          />

          <DefaultButton
            type="button"
            bg={colors.red}
            title="FINALIZAR A COMPRA"
            onClick={onSubmit}
            isLoading={isComprando || isLoadinEnderecos}
          />
        </Flex>
      </Stack>

      <TermoPoliticaModal visible={isOpenTermo} onClose={onCloseTermo} />

      <ModalEndereco
        data={enderecoFatura ?? {}}
        visible={isOpen}
        onClose={onClose}
        onSubmit={(data) => {
          onClose();
          setEnderecoFatura(data);
        }}
      />
    </Stack>
  );
}

export default PagarComCartao;
