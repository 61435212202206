import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Image,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import useHasEnderecoCadastrado from "core/features/auth/hooks/useHasEnderecoCadastrado";
import useComprarIngressoPix from "core/features/eventos/hooks/useComprarIngressoPix";
import colors from "core/resources/theme/colors";
import DefaultButton from "ui/components/Button/DefaultButton";
import ModalEndereco from "../../../ModalEndereco";
import StepCoponent from "./StepComponent";
import TermoPoliticaModal from "ui/pages/Admin/Ingresso/TermoPoliticaModal";
import Field from "ui/components/Input/Field";
import { useForm } from "react-hook-form";
import useGetUsuarioLogadoEnderecos from "core/features/auth/hooks/useGetUsuarioLogadoEnderecos";
import { useState } from "react";
import useGetUserInfo from "core/features/auth/hooks/useGetUserInfo";
import { validatePhone } from "core/helpers/number";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function IntroducaoPix() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { mutate, isLoading } = useComprarIngressoPix();
  const { hasAddress } = useHasEnderecoCadastrado();
  const { control, trigger } = useForm();
  const { data: enderecos, isLoading: isLoadinEnderecos } =
    useGetUsuarioLogadoEnderecos();
  const {
    isOpen: isOpenTermo,
    onClose: onCloseTermo,
    onOpen: onOpenTermo,
  } = useDisclosure();
  const { data: user } = useGetUserInfo();

  const [enderecoFatura, setEnderecoFatura] = useState(
    enderecos
      ? {
          cidade: enderecos?.nomeCidade,
          uf: enderecos?.nomeEstado,
          ...enderecos,
        }
      : null
  );
  const navigate = useNavigate();

  const onSubmit = async () => {
    if (!user) return;

    if (!validatePhone(user?.phone)) {
      const instance = Swal.fire({
        title: "Celular não informado",
        confirmButtonText: 'Ir para meus dados',
        icon: "warning",
        text: "É necessario ter o celular informado nas informações do usuário para adquirir ingressos.",
      });

      instance.then(({ isConfirmed }) => {
        if (isConfirmed) {
          setTimeout(() => {
            navigate("/perfil/alterarTelefone");
          }, 250);
        }
      });
      return;
    }
    const valued = await trigger();
    if (valued) {
      if (!hasAddress && !enderecoFatura) {
        onOpen();
        return;
      }
      mutate(enderecoFatura as any);
    }
  };

  return (
    <Flex flexDir="column" gap={8} w="full">
      <Box>
        <Text fontWeight="medium" fontSize="24px">
          Endereço de fatura
        </Text>
        {enderecoFatura ? (
          <>
            <Text>
              {enderecoFatura?.logradouro}, {enderecoFatura?.numero}
            </Text>
            <Text>
              {enderecoFatura?.cidade} - {enderecoFatura?.uf}
            </Text>
            <Text>{enderecoFatura?.cepInfo}</Text>
            <Button size={"xs"} bg={colors.red} color="white" onClick={onOpen}>
              Editar Endereço
            </Button>
          </>
        ) : (
          <>
            <Text color={colors.red}>
              Nâo possui endereço cadastrado ainda.
            </Text>
            <Button size={"xs"} bg={colors.red} color="white" onClick={onOpen}>
              Cadastrar Endereço
            </Button>
          </>
        )}
      </Box>
      <Stack align="center" justify="center">
        <Image
          src="/assets/pix.png"
          w="100%"
          maxW="100px"
          h="auto"
          objectFit="cover"
        />
        <Text fontWeight="medium" fontSize="24px">
          Como pagar via pix
        </Text>
      </Stack>

      <Center>
        <Flex flexDir="column" gap={4} w={["90%", "90%", "90%", "50%"]}>
          <StepCoponent
            step="1"
            textStep=" Abra o aplicativo do seu banco no celular."
          />
          <StepCoponent
            step="2"
            textStep=" Clique em Pix e escolha a opção Pagar por QR Code."
          />
          <StepCoponent
            step="3"
            textStep="Aponte a câmera do celular para o QR Code que será exibido após a
            finalização da compra."
          />
          <StepCoponent
            step="4"
            textStep="Pronto, agora é só confirmar os dados e finalizar o pagamento com
          o Pix."
          />
          <Field
            control={control}
            name="termo"
            rules={{
              required: "Você deve aceitar os termos e condições para comprar",
            }}
            render={({ field }) => (
              <Checkbox
                colorScheme="blue"
                mr={2}
                size="lg"
                spacing="1rem"
                {...field}
              >
                Li e concordo com as{" "}
                <Button variant="link" onClick={onOpenTermo}>
                  condições para compra
                </Button>
              </Checkbox>
            )}
          />
        </Flex>
      </Center>

      <Flex
        flexDir={["column"]}
        gap={4}
        mt={8}
        align={["end"]}
        justify={"space-between"}
      >
        <DefaultButton
          type="button"
          bg={colors.red}
          title="FINALIZAR A COMPRA"
          onClick={onSubmit}
          isLoading={isLoading || isLoadinEnderecos}
        />
      </Flex>
      <TermoPoliticaModal visible={isOpenTermo} onClose={onCloseTermo} />

      <ModalEndereco
        data={enderecoFatura ?? {}}
        visible={isOpen}
        onClose={onClose}
        onSubmit={(data) => {
          onClose();
          setEnderecoFatura(data);
        }}
      />
    </Flex>
  );
}
