import { Flex, Image } from "@chakra-ui/react";
import moment from "moment";
import colors from "core/resources/theme/colors";

import TagStatus from "./TagStatus";
import { getColorStatus, getIconStatus } from "../util";
import { useNavigate } from "react-router-dom";

interface TableItemRowProps {
  data: any;
}

export default function TableItemRow({ data }: TableItemRowProps) {
  const navigate = useNavigate();
  return (
    <Flex
      mt={1}
      bg={colors.gray10}
      w="100%"
      px={6}
      py={4}
      align="center"
      onClick={() => navigate(`/minhas-compras/${data.id}`)}
      cursor="pointer"
    >
      <Flex
        flex={0.2}
        fontSize="16px"
        fontWeight="medium"
        textDecoration="underline"
        color={colors.blue}
      >
        {data.id}
      </Flex>
      <Flex flex={0.8} fontSize="16px">
        {data.eventoNome}
      </Flex>
      <Flex flex={0.6} fontSize="16px">
        {moment(data.dataHora).format("DD/MM/YYYY")}
      </Flex>

      <Flex flex={0.4} fontSize="16px">
        {data.valorTotal.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })}
      </Flex>
      <Flex flex={1} fontSize="16px">
        <TagStatus
          title={data.statusNome}
          icon={
            <Image w="20px" h="auto" src={getIconStatus(data.statusNome)} />
          }
          colorScheme={getColorStatus(data.statusNome)}
        />
      </Flex>
    </Flex>
  );
}
