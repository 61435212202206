import {
  Grid,
  Box,
  Flex,
  Text,
  useMediaQuery,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MenuDetalhes from "ui/components/Menu/MenuDetalhes/index";
import colors from "core/resources/theme/colors";
import { IoTicketSharp } from "react-icons/io5";
import TableMinhasCompras from "./TableMinhasCompras";
import ListagemComprasMobile from "./ListagemComprasMobile";
import { useMeusIngressos } from "core/features/ingresso/hooks/useMeusIngressos";
import { useIngressosAbertos } from "core/features/ingresso/hooks/useIngressosAbertos";
import { useIngressosFinalizados } from "core/features/ingresso/hooks/useIngressosFinalizados";
import useIsLoggedIn from "core/features/auth/hooks/useIsLoggedIn";
import Swal from "sweetalert2";

export enum MinhasComprasRoutes {
  UtimosPedidos = "utimosPedidos",
  PedidosAndamento = "pedidosAndamento",
  PedidosFinalizados = "pedidosFinalizados",
}

const tabs = [
  {
    label: "Útimos pedidos",
    route: MinhasComprasRoutes.UtimosPedidos,
    permissions: [],
  },
  {
    label: "Pedidos em andamento",
    route: MinhasComprasRoutes.PedidosAndamento,
    permissions: [],
  },
  {
    label: "Pedidos finalizados",
    route: MinhasComprasRoutes.PedidosFinalizados,
    permissions: [],
  },
];

export default function MinhasCompras() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [isLargerThan1024] = useMediaQuery("(min-width: 1024px)");
  const isLogged = useIsLoggedIn();

  const tab = params.get("tab") as MinhasComprasRoutes | null;
  const [selectedTab, setSelectedTab] = useState<MinhasComprasRoutes>(
    tab || MinhasComprasRoutes.UtimosPedidos
  );
  const { data: ultimos, isLoading: ultimosLoading } = useMeusIngressos();
  const { data: abertos, isLoading: abertosLoading } = useIngressosAbertos();
  const { data: finalizados, isLoading: finalizadosLoading } =
    useIngressosFinalizados();

  const isLoading = ultimosLoading || abertosLoading || finalizadosLoading;
  const hasTickets =
    ultimos?.length > 0 || abertos?.length > 0 || finalizados?.length > 0;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogged) {
      const instance = Swal.fire({
        title: "Usuário não encontrado",
        icon: "error",
        text: "É necessario estar logado para visualizar suas compras",
      });

      instance.then(({ isConfirmed }) => {
        if (isConfirmed) {
          setTimeout(() => {
            navigate("/auth/login");
          }, 250);
        }
      });
    }
  }, [isLogged, navigate]);
  return (
    <Flex
      py={["150px", "150px", "150px", "350px"]}
      px={["50px", "50px", "50px", "200px"]}
      gap="80px"
      w="100%"
    >
      <Flex flexDir={["column"]} w="100%">
        {" "}
        <Text
          fontWeight="medium"
          fontSize="24px"
          color={colors.purple500}
          flexWrap="wrap"
        >
          MINHAS COMPRAS
        </Text>
        {isLoading ? (
          <Spinner
            alignSelf={"center"}
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : (
          <>
            {hasTickets ? (
              <Grid
                templateColumns={["1fr", "1fr", "1fr", "1fr", "1fr 4fr"]}
                gridGap={4}
                mt={8}
              >
                <Box h="fit-content">
                  <MenuDetalhes
                    options={tabs}
                    selectedOption={selectedTab}
                    onChangeOption={(tab) => {
                      setSelectedTab(tab);
                    }}
                  />
                </Box>

                <Box>
                  {selectedTab === MinhasComprasRoutes.UtimosPedidos ? (
                    isLargerThan1024 ? (
                      <TableMinhasCompras data={ultimos} />
                    ) : (
                      <ListagemComprasMobile data={ultimos} />
                    )
                  ) : null}
                  {selectedTab === MinhasComprasRoutes.PedidosAndamento ? (
                    isLargerThan1024 ? (
                      <TableMinhasCompras data={abertos} />
                    ) : (
                      <ListagemComprasMobile data={abertos} />
                    )
                  ) : null}
                  {selectedTab === MinhasComprasRoutes.PedidosFinalizados ? (
                    isLargerThan1024 ? (
                      <TableMinhasCompras data={finalizados} />
                    ) : (
                      <ListagemComprasMobile data={finalizados} />
                    )
                  ) : null}
                </Box>
              </Grid>
            ) : (
              <Flex w="100%" mt="150px" flexDir="column" align="center" gap={4}>
                <IoTicketSharp size={100} color="#88B1DD" />
                <Text fontSize="18px">Nenhum ingresso encontrado :(</Text>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
}
